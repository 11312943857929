<template>
    <div>
        <CRow>
            <CCol sm="12">
                <CCard style="width: 100%">
                    <CCardBody>
                        <CCardTitle>ACTUALIZAR DATOS.</CCardTitle>
                        <CRow>
                            <CCol sm="3" xs="3" lg="2"></CCol>
                            <CCol sm="7" xs="7" lg="7">
                                <CCol sm="12" xs="12" lg="12">
                                <input type="hidden" v-model="user.id">
                                <CInput
                                    label="Nombres"
                                    horizontal
                                    autocomplete="off"
                                    v-model="user.name"
                                />
                                </CCol>
                                <CCol sm="12" xs="12" lg="12">
                                <CInput
                                    label="Apellidos"
                                    horizontal
                                    autocomplete="off"
                                    v-model="user.last_name"
                                />
                                </CCol>
                                <CCol sm="12" xs="12" lg="12">
                                <CInput
                                    label="Correo Electrónico"
                                    horizontal
                                    autocomplete="off"
                                    v-model="user.email"
                                />
                                </CCol>
                                <CCol sm="12" xs="12" lg="12">
                                <CInput
                                    label="DNI"
                                    horizontal
                                    autocomplete="off"
                                    v-model="user.dni"
                                    maxlength="8"
                                />
                                </CCol>
                                <CCol sm="12" xs="12" lg="12">
                                <CInput
                                    label="Nueva Contraseña *"
                                    horizontal
                                    autocomplete="off"
                                    type="password"
                                    v-model="user.password"
                                />
                                </CCol>
                                <CCol sm="12" xs="12" lg="12">
                                    <button
                                        @click="mtd_update"
                                        type="button"
                                        class="btn-sm btn-primary float-right"
                                        >
                                        <i class="fas fa-save"></i>&nbsp;ACTUALIZAR
                                    </button>
                                </CCol>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
    data() {
        return {
            prefix: "profile",
            user: {
                id: null,
                dni: null,
                name: null,
                last_name: null,
                email: null,
                password: null
            }
        }
    },
    created(){
        this.mtd_getdata(this.$store.getters.get__user_id);
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtd_getdata: function (id) {
            this.get({
                url: this.$store.getters.get__url + "/users/"+id+"/show",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.user= {
                    id: response.id,
                    dni: response.dni,
                    name: response.name,
                    last_name: response.last_name,
                    email: response.email,
                }
            })
            .catch((errors) => {});
        },
        mtd_update: function (){
            this.post({
                url: this.$store.getters.get__url + "/users/update",
                token: this.$store.getters.get__token,
                params: this.user
            })
            .then((response) => {
                /** alerta */
                bus.$emit('alert', { 
                    color: "success",
                    message:"ACTUALIZADO CORRECTAMENTE"
                });

                if(this.user.password != null){
                    this.mtd_uppass();
                }
            })
            .catch((errors) => {});
        },
        mtd_uppass: function () {
            this.post({
                url: this.$store.getters.get__url + "/users/new_password",
                token: this.$store.getters.get__token,
                params: this.user
            })
            .then((response) => {
                this.mtd_logout();
            })
            .catch((errors) => {});
        },
        mtd_logout: function(){
            this.post({
                url: this.$store.getters.get__url + "/user/logout",
                params: []
            })
            .then((response) => {          
            if (response.state == 200) {
                this.$store.commit('mt_logout_auth')
                this.$router.replace({path: '/login'});
            }else{
                alert('error')
            }
            })
            .catch((errors) => {});
        },
    }
}
</script>